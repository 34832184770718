import VuexPersist from 'vuex-persist'
import { USER_STORAGE } from '@/scripts/app-configs/constants'

const userStorage = new VuexPersist({
  key: USER_STORAGE.key, // Store key
  storage: localStorage,
  reducer: (state) => {
    return {
      user: {
        user: state.user.user,
        flags: state.user.flags,
        institutions: { ...state.user.institutions, clientConfigsByInstitutionId: {} },
      },
    }
  },
  filter: (mutation) => {
    const userRegex = new RegExp(/^user\//)

    return userRegex.test(mutation.type) &&
                                          mutation.type !== 'user/authentication/loadStoredAuthTokens' &&
                                          mutation.type !== 'user/authentication/deleteAuthCookies' &&
                                          mutation.type !== 'user/resetProfile'
  },
})

const userStoragePlugin = userStorage

export default userStoragePlugin

import Vue, { computed, watch } from 'vue'
import store from '@/store'
import { useQueryClient } from '@tanstack/vue-query'
import type { AxiosError, AxiosResponse } from 'axios'

const ALLOWED_USER_ROLES = ['Professional']

type FetchUserFlagsType = {
  is_ai: boolean
  is_clinical_assistant_manager: boolean
  is_intake_pt: boolean
  is_lead: boolean
  is_treatment_pt: boolean
  has_provider_enabled: boolean
}

export type UserFlagsType = {
  isAIPt: boolean
  isLeadPt: boolean
  isTreatmentPt: boolean
  isIntakePt: boolean
  isClinicalAssistantManager: boolean
  hasProviderEnabled: boolean
}

export default function useUser() {
  const queryClient = useQueryClient()

  const userId = computed(() => store.getters['user/getUserId'])
  const isUserSet = computed(() => store.getters['user/userExist'])
  const authTokens = computed(() => {
    return {
      accessToken: store.getters['user/authentication/accessToken'],
      refreshToken: store.getters['user/authentication/refreshToken'],
    }
  })

  watch(isUserSet, () => {
    queryClient.removeQueries({ queryKey: ['fetch-user-flags'] })
  })

  async function login({ username, password }: { username: string, password: string }) {
    try {
      const { data: loginData } = await store.dispatch('user/authentication/validateCredentials', { username, password })
      const {
        role: userRole,
        access_token: accessToken,
        refresh_token: refreshToken,
      } = loginData

      if (!ALLOWED_USER_ROLES.includes(userRole)) {
        store.dispatch('loginFailed', 'ROLE_BLOCKED')

        return Promise.reject(Error('ROLE_BLOCKED'))
      }
      store.dispatch('user/authentication/updateAuthTokens', { accessToken, refreshToken }, { root: true })

      return Promise.resolve({ ...loginData })
    } catch (e) {
      if (!e || !(e as AxiosError).response) {
        console.warn('[login] User unable to login')
      }
      store.dispatch('loginFailed', (e as AxiosResponse).data)

      return Promise.reject(e)
    }
  }

  async function fetchUserFlags(): Promise<UserFlagsType> {

    try {
      const { data: { data: rawUserFlags } } = await queryClient.fetchQuery({
        queryKey: ['fetch-user-flags'],
        staleTime: Infinity,
        queryFn: () => Vue.$http('user/me/flags'),
      }) as { data: { data: FetchUserFlagsType }}

      const parsedFlags = {
        isAIPt: rawUserFlags.is_ai || false,
        isLeadPt: rawUserFlags.is_lead || false,
        isTreatmentPt: rawUserFlags.is_treatment_pt || false,
        isIntakePt: rawUserFlags.is_intake_pt || false,
        isClinicalAssistantManager: rawUserFlags.is_clinical_assistant_manager || false,
        hasProviderEnabled: rawUserFlags.has_provider_enabled || false,
      }

      return Promise.resolve(parsedFlags)
    } catch (e) {
      console.error('[fetch-user-flags] Error fetching user flags', e)

      return Promise.reject(e)
    }
  }

  return {
    userId,
    isUserSet,
    authTokens,
    login,
    fetchUserFlags,
  }
}

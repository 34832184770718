import Vue from 'vue'
import { errorSafeHandler } from '@/scripts/utils/api-error-utils'
import userStoragePlugin from '@/store/plugins/userStorage'

import { CONFIG } from '@/scripts/app-configs/constants'

// Modules
import authentication from './user/authenticationStore'
import preferences from './user/preferencesStore'

// Utils
import { triggers } from '@/scripts/global-modals-commands'

const defaultUserState = () => {
  return {
    user: {}, // Rename this when possible (ex. Profile)
    flags: {
      isAIPt: false,
      isLeadPt: false,
      isTreatmentPt: false,
      isIntakePt: false,
      isClinicalAssistantManager: false,
      hasProviderEnabled: false,
    },
    institutions: {
      list: [],
      clientConfigsByInstitutionId: {},
    },
  }
}

export default {
  namespaced: true,
  modules: {
    authentication,
    preferences,
  },
  state: {
    ...defaultUserState(),
  },
  getters: {
    getUserId: (state) => state.user && state.user.id,
    userExist: (state) => !!Object.keys(state.user).length,
    getInstitutionsList: (state) => state.institutions.list,
    getUserRole: (state) => state.user && state.user.role,
    getUserUsername: (state) => state.user && state.user.username,
    getUserFullName: (state) => `${state.user.firstname} ${state.user.lastname}`,
    enabledCommunication: (state) => {
      return {
        chat: !!state.user.sendbird?.sendbird_id,
        sms: !!state.user.detailedInfo?.externalPhoneNumber,
      }
    },
    getBasicProfile: (state, getters) => {
      if (!getters.userExist) return {}

      return {
        id: state.user.id,
        firstname: state.user.firstname,
        lastname: state.user.lastname,
        picture: state.user.picture,
        gender: state.user.gender,
        username: state.user.username,
        email: state.user.email,
        role: state.user.role,
        direct_message_address: state.user.direct_message_address,
        telephone: state.user.telephone,
        phone: state.user.phone,
        measurement_system: state.user.measurement_system,
      }
    },
    flags: (state) => state.flags,
    currentInstitutionId: (state, getters, rootState, rootGetters) => {
      return rootGetters['patient/getMemberBasics']?.institutionId || null
    },
    getClientsConfigs: (state) => state.institutions.clientConfigsByInstitutionId,
  },
  mutations: {
    setInstitutions(state, institutionsList) {
      state.institutions.list = institutionsList
    },
    setFlags(state, flags) {
      Object.entries(flags).forEach(([flag, value]) => {
        state.flags[flag] = value
      })
    },
    setInstitutionClientConfigs(state, { institutionId, configs }) {
      Vue.set(state.institutions.clientConfigsByInstitutionId, institutionId, configs)
    },
    setProfile(state, profile) {
      state.user = profile
    },
    setUserInfo(state, { sendbird, ...info }) { // This is a wip used to store things from /me endpoint
      Vue.set(state.user, 'detailedInfo', {
        externalPhoneNumber: info.external_phone_number,
      })
      Vue.set(state.user, 'sendbird', sendbird)
    },
    updateProfile(state, payload) {
      Object.keys(payload).forEach((key) => {
        state.user[key] = payload[key]
      })
    },
    resetProfile(state) {
      Object.assign(state, defaultUserState())
    },
    resetInstitutions(state) {
      const rawInstitutionsState = defaultUserState().institutions

      Object.entries(rawInstitutionsState).forEach(([prop, value]) => {
        Vue.set(state, prop, value)
      })
    },
  },
  actions: {
    async handleOnboardingProfileModal({ dispatch }) {
      const onboardingPromptDismissed = await dispatch('preferences/getPreference', 'dismissOnboardingProfileWarning')

      if (onboardingPromptDismissed) {
        return
      }
      const onboardingInfo = await dispatch('getTherapistInfo')
      const basicOnboardingProfileFilled = onboardingInfo.information && onboardingInfo.information.provides_remote_therapy
      const requiredOnboardingProfileInfoFilled = !!(onboardingInfo.information && onboardingInfo.information.specialities)

      if (basicOnboardingProfileFilled && requiredOnboardingProfileInfoFilled) {
        return
      }
      const modalContext = basicOnboardingProfileFilled ? 'incompleteProfile' : 'missingProfile'

      triggers.toggleOnboardingProfileMissing({ modalContext })
    },
    setUser({ commit, dispatch }, user) {
      try {
        delete user.institutions

        commit('setProfile', user)

        commit('system/changeLanguage', user.language, { root: true })

        const measurementSystem = user.measurement_system || !CONFIG.environment.includes('eu') ? 'imperial' : 'international'

        commit('system/changeMeasurementSystem', measurementSystem, { root: true })

        dispatch('preferences/clearPreferences')

      } catch (e) {
        console.error('[set-user] Error setting up fresh user', e)
      }

    },
    async deleteUser({ commit, dispatch }) {
      commit('resetProfile')
      commit('authentication/deleteAuthCookies')
      commit('authentication/clearAuthTokens')
      commit('resetInstitutions')
      commit('recommendations/resetRecommendations', null, { root: true })
      dispatch('preferences/clearPreferences')
      dispatch('system/resetSystem', null, { root: true })

      userStoragePlugin.storage.removeItem(userStoragePlugin.key)
    },
    async fetchUser({ commit, getters }) {
      const userId = getters.getUserId

      try {
        const { data } = await Vue.$http('user/getUser', userId)

        commit('updateProfile', data.user)
      } catch (e) {
        const { errorLevel } = errorSafeHandler(e)

        if (errorLevel.network) {
          console.error(`[fetch-user]: Error fetching user ${userId}`, e)
        }
      }
    },
    async updateProfessional({ getters }, payload) {
      const userId = getters.getUserId

      try {
        await Vue.$http('user/updateProfessional', userId, { body: payload })

        return Promise.resolve(true)
      } catch (error) {
        console.error(`[update-professional]: error updating professional ${userId}`, error)

        return Promise.reject(error)
      }
    },
    async getTherapistInfo({ getters }) {
      try {
        const userId = getters.getUserId
        const { data } = await Vue.$http('general/user/getTherapistInfo', userId)

        return Promise.resolve(data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    // TODO: remove when legacy sh-picture angular's component is not needed.
    async getUserPhoto(_, userId) {
      try {
        const { data } = await Vue.$http('general/user/getUserPhoto', userId)

        return Promise.resolve(data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
  },
}

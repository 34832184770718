export const TASK_TYPE = {
  PENDING_EXCLUSION: 'pending_exclusion',
  PENDING_PRESCRIPTION: 'pending_prescription',
  FOLLOW_UP_PRESCRIPTION: 'follow_up_prescription',
  PENDING_REPLY: 'pending_reply',
  PLAN_OF_CARE: 'plan_of_care',
  PROBLEMATIC_REASSESSMENT: 'problematic_reassessment',
  PROBLEMATIC_SESSION: 'problematic_session',
  PROTOCOL_PROGRESSION: 'protocol_progression',
  GUTENBERG_REACH_OUT: 'gutenberg_reachouts',
  CLINICAL_REFERRAL_REJECTION: 'referral_rejection',
  UPCOMING_CALL: 'upcoming_call',
  ACADEMY_SUGGESTION: 'academy_suggestion',
}

export const NEWSFEED_TYPES = {
  SCHEDULED_APPOINTMENT: ['video_call', 'phone_call', 'reassessment_call', 'check_in_call'],
  OWNERSHIP_TRANSFERS: ['ownership_temporary_transfer', 'ownership_permanent_transfer'],
}

export const DISMISS_TYPE = {
  NOT_RELEVANT: 'not-relevant',
  MESSAGE_SENT: 'message-sent',
  MARK_AS_DONE: 'mark-as-done',
  CALL_DONE: 'call_done',
  CALL_RESCHEDULE: 'call_reschedule',
  NO_SHOW: 'no_show',
}

export const SUPPORTED_REACH_OUT_TYPES = ['chat', 'phone', 'sms', 'voicemail']

export const TAB_ITEMS = {
  ACTIVITY: 'activity',
  HISTORY: 'history',
  PRESCRIPTIONS: 'prescriptions',
  RESULTS: 'results',
  RECORDS: 'records',
  PROFILE: 'profile',
}

<template>
  <div class="main-area">
    <loading-screen v-if="!appReady" :text="uiLoadingMessage" />
    <router-view v-if="appReady" />

    <!-- Global modals -->
    <sword-desk
      :class="{'summary-sidebar-open': globalModalsState.memberSidebar.show }"
      :modal-open="globalModalsState.swordDesk.show"
      :payload="globalModalsState.swordDesk.payload"
    />
    <timeout-manager />
    <notes-modal :modal-open="globalModalsState.notes.show" :payload="globalModalsState.notes.payload" />
    <notifications-feed :modal-open="globalModalsState.notificationsFeed.show" />
    <recommendations-feed :modal-open="globalModalsState.recommendationsFeed.show" />
    <recommendation-reasons :modal-open="globalModalsState.recommendationsReasons.show" />
    <patient-summary-sidebar :modal-open="globalModalsState.memberSidebar.show" :payload="globalModalsState.memberSidebar.payload" />
    <onboarding-profile-missing-modal
      :modal-open="globalModalsState.onboardingProfileMissing.show"
      :payload="globalModalsState.onboardingProfileMissing.payload"
    />
    <chat-popup
      v-if="isAuthenticated && !isDemo"
      :modal-open="globalModalsState.chatPopup.show"
      :payload="globalModalsState.chatPopup.payload"
      :member-sidebar-open="globalModalsState.memberSidebar.show"
    />

    <theme-selector v-if="showThemeSelector" :current-theme="currentTheme" @change-theme="selectPortalTheme" />
  </div>
</template>

<script>

import { ref, computed, onBeforeMount, onMounted } from 'vue'
import store from '@/store'

// Composables
import useExercisesAssets from '@/composables/use-exercises-assets'
import useUser from '@/composables/useUser'

// Configs
import { VERSION_FILE } from '@/scripts/app-configs/constants'

// Tools
import ACLModule from '@/scripts/integrations/acl-module'
import { selectTheme } from '@ui-kit/themes'

// Components
// -- Global Modals
import OnboardingProfileMissingModal from '@/components/modals/onboarding-profile-missing-modal.vue'
import NotesModal from '@/components/modals/member-notes/NotesModal.vue'
import NotificationsFeed from '@/components/notifications/NotificationsFeed.vue'
import RecommendationsFeed from '@/components/recommendations/RecommendationsFeed.vue'
import RecommendationReasons from '@/components/recommendations/RecommendationReasons.vue'
import PatientSummarySidebar from '@/components/modals/PatientSummarySidebar.vue'
import TimeoutManager from '@/components/timeout-manager.vue'
import ChatPopup from '@/views/chat/components/ChatPopup.vue'
import SwordDesk from '@/components/sword-desk/sword-desk.vue'
// -- Generic
import LoadingScreen from '@/components/feedbacks/LoadingScreen.vue'
import ThemeSelector from '@sword-health/web-ui-kit/src/dev-utils/theme-selector/theme-selector.vue'
import * as config from './scripts/app-configs/config'

// Utils
import { events, utils } from '@/scripts/global-modals-commands'

export default {
  name: 'App',
  components: {
    LoadingScreen,
    OnboardingProfileMissingModal,
    NotesModal,
    NotificationsFeed,
    RecommendationsFeed,
    RecommendationReasons,
    PatientSummarySidebar,
    TimeoutManager,
    ThemeSelector,
    ChatPopup,
    SwordDesk,
  },
  setup() {
    const currentTheme = ref('sword')
    const showThemeSelector = process.env.NODE_ENV === 'development' && false

    const appReady = computed(() => store.getters['system/uiReady'])
    const uiLoadingMessage = computed(() => store.getters['system/uiLoadingMessage'])
    const isAuthenticated = computed(() => store.getters['user/authentication/isAuthenticated'])

    const isDemo = config.get('DEMO_MODE') === 'true'
    const { isUserSet, fetchUserFlags } = useUser()
    const exerciseAssetsVersion = config.get('ASSETS_VERSION')

    const globalModalsState = ref(utils.modalStateUtil)

    events.onGlobalModalTrigger((modalKey, action, payload) => {
      globalModalsState.value[modalKey] = utils.updateModalStateUtil(globalModalsState.value[modalKey], action, payload)
    })

    useExercisesAssets(exerciseAssetsVersion, { fetchEnabled: isUserSet })

    const selectPortalTheme = (themeName) => {
      selectTheme(themeName)
      currentTheme.value = themeName
    }

    onMounted(async () => {
      selectPortalTheme(currentTheme.value)

      if (isUserSet.value) {
        store.dispatch('user/authentication/loginSuccess')

        // We can remove above fetch after a while. This is just to avoid requesting all users to login again
        const flags = await fetchUserFlags()

        store.commit('user/setFlags', flags)
      }
    })

    onBeforeMount(() => {
      store.commit('system/setAppVersion', { context: 'build', versionObj: VERSION_FILE })
      store.commit('user/authentication/loadStoredAuthTokens')

      ACLModule.requestUserAccess().catch(() => {
        store.commit('system/setUIReadyState', true)
        ACLModule.redirectToSignIn()
      })
    })

    return {
      globalModalsState,
      appReady,
      isAuthenticated,
      isDemo,
      currentTheme,
      showThemeSelector,
      uiLoadingMessage,
      selectPortalTheme,
    }
  },
  mounted() {
    this.$zendesk.init()
  },
  beforeDestroy() {
    this.$zendesk.hide()
  },
}
</script>

export default {
  protocols: {
    getProtocolsDefinitions: {
      api: {
        url: 'default',
        version: '1',
      },
      $$method: 'get',
      $$config: {
        headers: (data) => data.headers.authorization,
      },
      $$makeUrl(configs, { numberOfWeeks, scheduler, isVisible = null }) {
        const params = new URLSearchParams()

        if (numberOfWeeks) params.append('number_of_weeks', numberOfWeeks)
        if (scheduler?.length) params.append('days_with_sessions', scheduler.length)
        if (isVisible !== null) params.append('is_visible', isVisible)

        return `therapist/v${configs.api.version}/move/protocols?${params.toString()}`
      },
    },
  },
  members: {
    getPrescriptionsSettings: {
      api: {
        url: 'default',
        version: '1',
      },
      $$method: 'get',
      $$config: {
        headers: (data) => data.headers.authorization,
      },
      $$makeUrl(configs, { accountUUID, programUUID }) {
        return `therapist/v${configs.api.version}/move/members/${accountUUID}/programs/${programUUID}/prescriptions/settings`
      },
    },
    savePrescription: {
      api: {
        url: 'default',
        version: '2',
      },
      $$method: 'post',
      $$config: {
        headers: (data) => data.headers.authorization,
      },
      $$makeUrl(configs) {
        return `therapist/v${configs.api.version}/move/member/prescriptions`
      },
    },
    getPrescriptions: {
      api: {
        url: 'default',
        version: '1',
      },
      $$method: 'get',
      $$config: {
        headers: (data) => data.headers.authorization,
      },
      $$makeUrl(configs, { accountUUID, programUUID }) {
        const params = new URLSearchParams()

        params.append('current_prescription', true)

        return `therapist/v${configs.api.version}/move/members/${accountUUID}/programs/${programUUID}/prescriptions`
      },
    },
    deletePrescription: {
      api: {
        url: 'default',
        version: '1',
      },
      $$method: 'delete',
      $$config: {
        headers: (data) => data.headers.authorization,
      },
      $$makeUrl(configs, { memberId, prescriptionId }) {
        return `therapist/v${configs.api.version}/move/members/${memberId}/prescriptions/${prescriptionId}`
      },
    },
    getActivities: {
      api: {
        url: 'default',
        version: '1',
      },
      $$method: 'get',
      $$config: {
        headers: (data) => data.headers.authorization,
      },
      $$makeUrl(configs, { key }) {
        const queryParams = new URLSearchParams()

        queryParams.append('key', key)

        return `therapist/v${configs.api.version}/move/member/activities?${queryParams}`
      },
    },
    getPrescriptionOverview: {
      api: {
        url: 'default',
        version: '1',
      },
      $$method: 'get',
      $$config: {
        headers: (data) => data.headers.authorization,
      },
      $$makeUrl(configs, { memberId, prescriptionId }) {
        const params = new URLSearchParams()

        params.append('current_prescription', true)

        return `therapist/v${configs.api.version}/move/members/${memberId}/prescriptions/${prescriptionId}/overview`
      },
    },
    updatePrescription: {
      api: {
        url: 'default',
        version: '1',
      },
      $$method: 'put',
      $$config: {
        headers: (data) => data.headers.authorization,
      },
      $$makeUrl(configs, { prescriptionId }) {
        return `therapist/v${configs.api.version}/move/member/prescriptions/${prescriptionId}`
      },
    },
  },
}
